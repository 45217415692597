<template>
  <v-container fluid>
    <v-sheet class="d-flex mx-auto py-4" min-height="80vh" :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'">
      <v-row no-gutters>
        <v-col cols="12" md="3" class="px-2 py-4">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="text-center">
              <v-icon size="125" color="activeButton">mdi-card-account-details-outline </v-icon>
            </v-col>
            <v-col cols="12">
              <p class="text-h5 mb-0 text--secondary text-center font-weight-medium">{{ currentUser.name }}</p>
            </v-col>
            <v-col cols="12">
              <p class="text-h6 mb-0 text--secondary text-center font-weight-medium">{{ currentUser.organizationName }}</p>
            </v-col>
          </v-row>
          <div class="d-block mt-8 px-4">
            <v-menu top close-on-content-click v-if="UserId == user.id || hasAccess('ModifyStaff')">
              <template v-slot:activator="{ on, attrs }">
                <r-btn class="mb-3" block v-bind="attrs" v-on="on">Kodeord</r-btn>
              </template>
              <v-list class="my-0 pt-2 pb-0">
                <v-list-item>
                  <r-btn class="mb-3" @click="$refs.ChangePasswordRef.changePassword(currentUser, true)" block>
                    <v-icon left>mdi-lock-reset</v-icon>
                    Skift kodeord
                  </r-btn>
                </v-list-item>
                <v-list-item>
                  <r-btn class="mb-3" @click="sendPasswordChangeEmail" block>
                    <v-icon left>mdi-email-arrow-right-outline</v-icon>
                    Nulstil kodeord
                  </r-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <r-btn @click="UserCalender" block class="mb-3">
              <v-icon left>mdi-calendar-account</v-icon>
              Se kalender
            </r-btn>
            <r-btn @click="editUser" block class="mb-3" v-if="UserId == user.id || hasAccess('ModifyStaff')">
              <v-icon left>mdi-pencil</v-icon>
              Rediger Bruger
            </r-btn>
            <r-btn class="mb-3" block :loading="updatingState" @click="deleteStaff" v-if="UserId == user.id || hasAccess('ModifyStaff')" delete>
              <v-icon left>mdi-cancel</v-icon>
              Slet
            </r-btn>
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" class="px-2 py-4 text-subtitle-1">
          <v-row no-gutters class="text-left px-4"> <p class="text-h6 text--secondary font-weight-medium my-auto">Instruktør Detaljer</p> </v-row>
          <div class="px-4">
            <v-row class="px-2 my-1">
              <v-col>Navn:</v-col>
              <v-col cols="auto">{{ currentUser.name }}</v-col>
            </v-row>
            <v-divider />
            <v-row v-if="currentUser.email" class="px-2 my-1">
              <v-col>Email:</v-col>
              <v-col cols="auto">{{ currentUser.email }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Telefon nummer:</v-col>
              <v-col cols="auto">{{ currentUser.phoneNumber }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>CVR:</v-col>
              <v-col cols="auto">{{ currentUser.cvr }}</v-col>
            </v-row>
            <v-divider />
            <v-row v-if="isAdmin" class="px-2 my-1">
              <v-col>Roller:</v-col>
              <v-col cols="auto"
                ><v-chip v-for="(item, index) in currentUser.roles" :key="index" class="mr-1 NoHover">
                  {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                </v-chip></v-col
              >
            </v-row>
            <v-divider />
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="5" class="px-2 py-4">
          <div class="d-flex flex-column" style="height: 74vh">
            <div class="d-flex px-4">
              <p class="text-h6 text--secondary font-weight-medium my-auto">Elever</p>
            </div>
            <div v-if="currentUser" style="overflow-y: auto">
              <div v-for="(item, index) in currentUser.students" :key="index" class="mb-4 mt-1 px-4">
                <v-card elevation="2">
                  <v-row no-gutters>
                    <v-col class="px-3 py-2">
                      <p class="text-h6 mb-2">{{ item.name }}</p>
                      <p class="text-subtitle-1">{{ item.organizationName }}</p>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center mr-2">
                      <r-btn color="Activebutton" x-large icon @click="userDetails(item)" title="Detaljer">
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </r-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <create-edit-user ref="CreateEditUserRef" @user-updated="getUserDetails" />
    <confirmation-dialog ref="confirmationDialogRef" />
    <change-password-dialog ref="ChangePasswordRef" />
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/dialogs/CreateEditUser.vue';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'User-Details',
  components: {
    CreateEditUser,
    ChangePasswordDialog,
  },
  props: {
    UserId: String,
  },
  watch: {
    UserId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getUserDetails();
        }
      },
    },
  },
  data: () => ({
    updatingState: false,
    loading: false,
    currentUser: {},
    studentListHeaders: [
      {
        text: 'Navn',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Tlf. nummer',
        value: 'phoneNumber',
      },
    ],
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'isStaff', 'hasAccess']),
    studentIsAssigned() {
      return this.currentUser.staffId !== null;
    },
  },
  methods: {
    async deleteStaff() {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Deaktiver kørelærer',
        studentName: this.currentUser.name,
        body: `Ønsker du at deaktivere denne kørelærer?`,
        okBtn: 'Slet',
      });
      if (dialogResponse) {
        this.updatingState = true;
        await apiService.deactivateStaff(this.currentUser.id);
        this.updatingState = false;
        this.$router.push({ name: 'staff' });
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
    async getUserDetails() {
      this.currentUser = await apiService.getStaffById(this.UserId);
    },
    editUser() {
      this.$refs.CreateEditUserRef.editUser(this.currentUser.id, true);
    },
    UserCalender() {
      this.$router.push({ name: 'Calendar', params: { UserId: this.currentUser.id } });
    },
    async saveChanges() {
      this.loading = true;
      await apiService.updateUser(this.currentUser).then(() => {
        this.getUserDetails();
        this.loading = false;
      });
    },
    async sendPasswordChangeEmail() {
      await apiService.resetPassword(this.currentUser.email);
      this.$store.commit('alert', {
        show: true,
        title: 'Email sendt',
        color: 'success',
        message: `Email Kodeord-reset request er blevet sendt til  ${this.currentUser.email}`,
      });
    },
    userDetails(user) {
      this.$router.push({ name: 'StudentDetails', params: { UserId: user.id } });
    },
  },
};
</script>

<style lang="scss">
.NoHover tr:hover,
.NoHover:before {
  background-color: transparent !important;
}
</style>